import React, {useEffect, useState} from 'react';
import Card from "../components/Card/Card";
import Modal from "../components/Modal/Modal";
import {useEtherBalance, useEthers, Goerli, Mainnet} from "@usedapp/core";
import {ethers, utils} from "ethers";
import Connect from "../components/Connect";
import {formatEther} from "@ethersproject/units";
import json from '../db/users.json'
import {useContractMethod} from "../utils/Transaction";
import classNames from "classnames";
import {useNavigate} from "react-router-dom";
import video from "../assets/bg-video.mp4"
import imageBg from "../assets/new_bg_laptop.jpg"

const HomeScreen = () => {

  // Состояния меняют дизайн кнопок
  const {activateBrowserWallet, account, deactivate, chainId, Signer} = useEthers();
  const {state, send: presaleMint} = useContractMethod("presaleMint");
  const [modalController, setModalController] = useState(null)
  const [modalControllerConnect, setModalControllerConnect] = useState(null)
  const [modalControllerCheck, setModalControllerCheck] = useState(null)
  const [modalControllerMind, setModalControllerMind] = useState(null)
  const [modalWarning, setModalWarning] = useState(null)
  const [modalBye, setModalBye] = useState(null)
  const [isBye, setIsBye] = useState(false)

  const navigate = useNavigate()

  const etherBalance = useEtherBalance(account) || 0

  useEffect(() => {
    if (account) {
      setWalletConnect({...walletConnect, status: true, nameButton: "CONNECTED"})
      setModalControllerConnect(true)
    } else {
      setWalletConnect({...walletConnect, status: false, nameButton: "CONNECT"})
    }
  }, [account])

  useEffect(() => {
    if (walletConnect.status) setWalletConnect({
      ...walletConnect,
      status: true,
      balance: `${etherBalance && parseFloat(formatEther(etherBalance)).toFixed(8)} ETH`
    })
  }, [etherBalance])

  useEffect(() => {
    if (state.status === "Success") {
      setModalController(true)
    } else if (state.status === "Fail") {
      setModalController(false)
      setWalletMinted({...walletMinted, nameButton: "TRY AGAIN", status: false})
    }
  }, [state])

  const handlerConnect = () => {
    activateBrowserWallet();
  }

  const handlerCheck = () => {
    // console.log(account)
    const isFound = json.find(e => e.user === account)
    if (isFound !== undefined) {
      setWalletCheck({...walletCheck, status: true, nameButton: "YOU\`RE WHITELISTED",})
      setModalControllerCheck(true)
    } else {
      setWalletCheck({
        ...walletCheck,
        status: false,
        nameButton: "CHECK",
        alertMessageInit: "Sorry, but you're not Whitelisted. \nIf you think that we're wrong, \ngo to discord and create the ticket."
      })
      setModalControllerCheck(false)
    }
  }

  const handlerMinted = async () => {

    const date = new Date(Date.UTC(2022, 10, 19, 18, 0, 0))
    let dateCur = new Date();
    let dateUtcCur = Date.UTC(dateCur.getUTCFullYear(), dateCur.getUTCMonth(),
      dateCur.getUTCDate(), dateCur.getUTCHours(),
      dateCur.getUTCMinutes(), dateCur.getUTCSeconds());

    if (dateUtcCur < date) {
      setModalBye(false)
      return
    }

    if (parseFloat(formatEther(etherBalance)).toFixed(2) >= 0.25 && !isBye) {
      if(chainId !== Goerli.chainId) switchNetwork(Goerli.chainId)
      const signer = new ethers.Wallet(process.env.REACT_APP_PRIVATE_KEY)
      console.log(signer)
      const message = ethers.utils.solidityKeccak256(['address'], [account]);
      console.log(message)
      const signature = await signer.signMessage(ethers.utils.arrayify(message));
      console.log(signature)
      presaleMint(signature, { value: utils.parseEther("0.25") })

      setWalletMinted({...walletMinted,nameButton: "SUCCESSFULLY MINTED", status: true, type: "green"})
      setModalControllerMind(true)
      setIsBye(true)
    } else if (isBye) {
       setModalBye(true)
    } else {
      setModalControllerMind(false)
      setWalletMinted({...walletMinted, status: false, type: ""})
    }
  }

  const handlerDisconnect = () => {
    setModalWarning(true)
    deactivate()
    setWalletConnect({...walletConnect, status: false,})
    setWalletCheck({...walletCheck, status: false, alertMessageInit: null})
    setWalletMinted({...walletMinted, status: false})
  }


  const [walletConnect, setWalletConnect] = useState({
    label: `CONNECT WALLET`,
    nameButton: `Connect`,
    balance: "",
    status: false,
    className: "bg-pColorGreen ",
    disconnect: handlerDisconnect,
    type: "green",

  })

  const [walletCheck, setWalletCheck] = useState({
    label: "CHECK, \nIF YOU`RE WHITELISTED",
    nameButton: `CHECK`,
    status: false,
    className: "bg-pColorGreen",
    classCardInit: "opacity-30 pointer-events-none",
    type: "",
    alertMessageInit: null,
  })

  const [walletMinted, setWalletMinted] = useState({
    label: `MINT VOIDERS GEN.`,
    nameButton: `MINT (0.25ETH)`,
    status: false,
    className: "bg-pColorGreen ",
    classCardInit: "opacity-30 pointer-events-none",
    classInit: "bg-pColorBlue hover:bg-pModalPurpur",
    type: "green",
  })

  const nopeTryAgain = () => {
    setWalletMinted({...walletMinted, label: "NOPE! TRY AGAIN", classInit: "bg-pColorRed"})
  }

  useEffect(() => {

    if (walletCheck.alertMessageInit) {
      setWalletMinted({...walletMinted, label: "Check, \nif you’re whitelisted"})
    } else {
      setWalletMinted({...walletMinted, label: "MINT VOIDERS GEN."})
    }

  }, [walletCheck.alertMessageInit])

  useEffect(() => {

    if (walletConnect.status) {
      setWalletCheck({...walletCheck, classCardInit: null})
    } else {
      setWalletCheck({...walletCheck, classCardInit: "opacity-30 pointer-events-none"})
    }

  }, [walletConnect.status])

  useEffect(() => {

    if (walletConnect.status) {
      setWalletCheck({...walletCheck, type: "green"})
      setWalletMinted({...walletMinted, classCardInit: null})
    } else {
      setWalletMinted({...walletMinted, classCardInit: "opacity-30 pointer-events-none "})
    }

  }, [walletCheck.status])

  // const handlerClickPage = (e) => {
  //   if (e.keyCode === 71) {
  //     navigate('/users')
  //   }
  // }
  //
  // useEffect(() => {
  //   document.onkeydown = handlerClickPage
  //   return () => {
  //     return removeEventListener("onkeydown", handlerClickPage)
  //   }
  // }, [])


  return (
    <div className={"w-full h-full flex flex-col bg_custom justify-center bg-no-repeat bg-cover"}>

      <video className={"laptop:hidden w-full h-auto fixed top-0 left-0 z-0"} poster={imageBg} autoPlay={true} muted={true} loop={true}>
        <source src={video} type={"video/mp4"} />
      </video>

      {
        modalController === true &&
        <Modal status={true} controller={setModalController} btnContent={"WOHO!"}
               content={"You have successfully\n" +
                 "minted NFT"} title={"THANK YOU \n" + "CO-FOUNDER!"} active={true}
               subText={"Voiders team thanks you! \nNow you`re our Co-Founder."}>
          You have <div className={"text-pColorGreen"}>SUCCESSFULLY</div> <br/>
          minted NFT
        </Modal>
      }
      {
        modalController === false && <Modal status={false} controller={setModalController} btnContent={"TRY AGAIN"}
                                            content={"But the transaction \n was unsuccessfull."}
                                            title={"SAD TO HEAR"} active={true}
                                            subText={"Something went wrong. \nPlease, check, if you have sufficient funds on your account."}>
          But the transaction
          was <div className={"text-pColorRed"}>DECLINED</div>.
        </Modal>
      }


      {
        modalControllerConnect === true &&
        <Modal status={true} controller={setModalControllerConnect} btnContent={"OK"}
               content={"Your wallet successfully connected"} title={"THANK YOU!"} active={true}>
          Your wallet <div className={"text-pColorGreen uppercase"}>successfully</div> connected
        </Modal>
      }
      {
        modalControllerConnect === false &&
        <Modal status={false} controller={setModalControllerConnect} btnContent={"TRY AGAIN"}
               content={"The wallet is NOT connected. Maybe you cancelled it?"}
               title={"SOMETHING WRONG"} active={true}>
          The wallet is <div className={"text-pColorRed"}>NOT CONNECTED</div> . Maybe you cancelled it?
        </Modal>
      }


      {
        modalControllerCheck === true &&
        <Modal status={true} controller={setModalControllerCheck} btnContent={"OK"} content={"Your are \nWHITELISTED"}
               subText={"Go to the next step, \nto become our Co-Founder."} title={"THANK YOU!"} active={true}>
          Your are <br/>
          <div className={"text-pColorGreen"}>WHITELISTED</div>
        </Modal>
      }
      {
        modalControllerCheck === false &&
        <Modal status={false} controller={setModalControllerCheck} btnContent={"TRY AGAIN"}
               content={"YOU ARE \nNOT WHITELISTED"}
               subText={"Do you think we`re wrong? \nGo to discord and create ticket."}
               title={"NOPE"} active={true}>
          YOU ARE <br/>
          <div className={"text-pColorRed"}>NOT WHITELISTED</div>
        </Modal>
      }

      {
        modalControllerMind === true &&
        <Modal status={true} controller={setModalControllerMind} btnContent={"CLOSE"}
               content={"Your are \nWHITELISTED"} subText={""}
               title={"CHECK METAMASK"} active={true}>
          <div className={"text-pColorGreen"}>Everything is fine.</div><br/>
          All you need is check the metamask and sign.
        </Modal>
      }
      {
        modalControllerMind === false &&
        <Modal status={false} controller={setModalControllerMind} btnContent={"TRY AGAIN"}
               content={"YOU ARE \nNOT WHITELISTED"}
               subText={"Do you think we`re wrong? \nGo to discord and create ticket."}
               title={"insufficient funds"} active={true}>
          Transaction
          was
          <div className={"text-pColorRed"}>DECLINED</div>
          due to insufficient funds.
        </Modal>
      }


      {
        modalWarning === true &&
        <Modal status={false} controller={setModalWarning} btnContent={"CLOSE"} content={"Your are \nWHITELISTED"}
               subText={""} title={"WARNING"} active={true}>
          The wallet has been<br/>
          <div className={"text-pColorRed"}>DISCONNECTED.</div>
        </Modal>
      }


      {
        modalBye === true &&
        <Modal status={true} controller={setModalBye} btnContent={"WOHO!"} content={"Your are \nWHITELISTED"}
               subText={"No need to do anything. \n In a couple if minutes, Voiders Gen. NFT wil be in your wallet. \n Remember. Only one NFT on one wallet."} title={"WARNING"} active={true}>
          You<br/>
          <div className={"text-pColorGreen"}>ALREADY SUCCESSFULLY</div>
          minted the NFT.
        </Modal>
      }
      {
        modalBye === false &&
        <Modal status={true} controller={setModalBye} btnContent={"CLOSE"} content={"Your are \nWHITELISTED"}
               title={"PRESALE IS NOT ACTIVE"} active={true}>
          You came here to early.<br/>
          Please, come back
          <div className={"text-pColorGreen"}>
            19th of November <br/>
            18:00 GMT(UTC)
          </div>
        </Modal>
      }



      <div
        className="relative z-10 flex flex-col gap-[32px]  tablet:my-32 laptop:gap-2 px-[52px] laptop:px-[16px] tablet:px-[8px]">

        <div className="w-fit h-fit ml-4">
          <div
            className="text-[77px] leading-[76px] laptop:text-[36px] laptop:leading-[48px] font-bold text-white font-projectTitle">
            VOIDERS GENESIS <br/> PRESALE!

          </div>
        </div>


        <div className="max-w-[700px] w-full flex flex-col">

          <div
            className="tablet:px-4 h-[161px] w-full flex text-white flex-col items-end px-12 justify-center bg-pBgSiteBar bg-no-repeat bg-cover">
            <div
              className="tablet:text-[32px] w-fit h-fit font-bold text-[54px] leading-[40px] font-projectTitle">
              READY TO MINT ?
            </div>
            <div className={classNames([
              "transition-all duration-800 tablet:text-[14px] text-[22px]",
              {"max-h-[0px] ": !account},
              {"max-h-[24px]": account},
            ])}>
              {account ? account : ""}
            </div>
          </div>

          <div className="blur_site_bar flex flex-col gap-[21px] py-[21px] mx-4  tablet:mx-0">

            <Card type={walletConnect.type} label={walletConnect.label} className={walletConnect.className}
                  disconnect={walletConnect.disconnect} nameButton={walletConnect.nameButton}
                  status={walletConnect.status}
                  classCardInit={walletConnect?.classCardInit}
                  onClick={handlerConnect} balance={walletConnect.balance}
                  alertMessagedInit={walletConnect?.alertMessageInit}
                  classInit={walletConnect.classInit}

            />

            <Card type={walletCheck.type} label={walletCheck.label} className={walletCheck.className}
                  disconnect={walletCheck.disconnect} nameButton={walletCheck.nameButton}
                  status={walletCheck.status}
                  classCardInit={walletCheck.classCardInit}
                  onClick={handlerCheck} balance={walletCheck.balance}
                  alertMessagedInit={walletCheck?.alertMessageInit}
                  classInit={walletCheck.classInit}
            />

            <Card type={walletMinted.type} label={walletMinted.label} className={walletMinted.className}
                  disconnect={walletMinted.disconnect} nameButton={walletMinted.nameButton}
                  status={walletMinted.status}
                  classCardInit={walletMinted.classCardInit}
                  onClick={handlerMinted} balance={walletMinted.balance}
                  alertMessagedInit={walletMinted?.alertMessageInit}
                  classInit={walletMinted.classInit}/>

          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeScreen;