import React, {useEffect, useState} from 'react';
import Button from "../Button/Button";
import classNames from "classnames";
import {observer} from "mobx-react-lite";

const Card = ({ label, nameButton, onClick, balance, status, className, disconnect, type, alertMessagedInit, classInit,classCardInit }) => {


  const [labelInit, setLabelInit] = useState(label)
  const [styleDepth, setStyleDepth] = useState("")

  useEffect( () => {
    if (alertMessagedInit){
      setLabelInit("OUCH!")
    } else {
      setLabelInit(label)
    }
  }, [alertMessagedInit])

  useEffect(() => {
    if (type === "red") {
      setStyleDepth("gradient_line red")
    } else if (type === "green") {
      setStyleDepth("gradient_line green")
    } else {
      setStyleDepth("gradient_line")
    }
  }, [type, alertMessagedInit])

  return (
      <div className={classNames([
        "flex h-[106px] transition-all laptop:h-auto laptop:gap-8 relative laptop:flex-col text-white items-center justify-between py-2 px-4 ",
        { "bg-gradient": !alertMessagedInit },
        { "bg-gradient-error": !!alertMessagedInit },
        { [styleDepth]: styleDepth },
        { [classCardInit]: classCardInit }
      ])}>
        <div className={classNames([
          "text-[28px] leading-[26px] whitespace-pre-wrap",
          { "mb-12": !!alertMessagedInit }
        ])}>
          {labelInit}
          { balance && <div className="font-bold ">{balance}</div> }
          { alertMessagedInit && <div className={"border_remove text-base px-4 py-1 bg-no-repeat bg-contain leading-[16px] absolute bottom-0 left-0"}>
            {alertMessagedInit}
          </div> }
        </div>
        <Button
          onClick={onClick}
          status={status}
          alertMessagedInit={alertMessagedInit}
          classInit={classInit}
          className={className}
          disconnect={disconnect}

        >
          {nameButton}
        </Button>
      </div>
  );
};

export default observer(Card);