import { Contract } from '@ethersproject/contracts'
import {useContractFunction} from "@usedapp/core";
import abiVoid from "../Contract/VoidersGenesisAbi.json"
import { ethers } from "ethers";

const simpleContractInterface = new ethers.utils.Interface(abiVoid);
const contract = new Contract("0x1B11592B0Fc6E78EA1B399465AB64145277087C1", simpleContractInterface);

export function useContractMethod(methodName) {
    const { state, send } = useContractFunction(contract, methodName, {});
    return { state, send };
}
