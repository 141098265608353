import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import HomeScreen from "./screens/Home.screen";
import ListuserScreen from "./screens/Listuser.screen.";


const _Routes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/users" element={<ListuserScreen />} />
      </Routes>
    </BrowserRouter>
  );
};

export default _Routes;