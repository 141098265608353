import React from 'react';
import json from '../db/users.json'

const ListuserScreen = () => {
  return (
    <div className={"w-screen flex flex-col items-center py-10"}>

      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
          <tr>
            <th scope="col" className="py-3 px-6">
              #
            </th>
            <th scope="col" className="py-3 px-6">
              ADDRESS
            </th>
          </tr>
          </thead>
          <tbody>
          {
            json.map((el, index) => {
              return (
                <tr key={index} className="bg-white border-b ">
                  <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap ">
                    {index + 1}
                  </th>
                  <td className="py-4 px-6">
                    {el.user}
                  </td>
                </tr>
              )
            })
          }
          </tbody>
        </table>
      </div>

    </div>
  );
};

export default ListuserScreen;