import React, {useEffect} from 'react';
import {useEtherBalance, useEthers} from "@usedapp/core";

const Connect = ({setBalance}) => {
    const { account } = useEthers();

    const etherBalance = useEtherBalance(account)
    useEffect(() => {
        setBalance(etherBalance)
    }, [etherBalance])
    return (
        <div>

        </div>
    );
};

export default Connect;
