import React, {useEffect, useRef, useState} from 'react';
import Button from "../Button/Button";
import classNames from "classnames";

const Modal = ({children, title, content, btnContent, active = false, controller, subText, status}) => {

  const [isActive, setIsActive] = useState(active)

  const changeHandlerActive = (e) => {
    setIsActive(!isActive)
    controller(null)
  }

  const handlerStyleClick = (e) => {

  }

  return (
    <div className={classNames([
      "w-screen h-screen top-0 left-0 z-30 fixed bg-pModalGay flex flex-col items-center justify-center",
      { "hidden": !isActive },

    ])}>

      <div className={classNames([
        "w-[700px] tablet:w-auto z-40 py-2",
        { "border-pColorRed border": !status },
        { "border-pColorGreen border": status }
      ])}>

        <div className="w-full px-16 text-center leading-[55px] flex flex-col items-center justify-center text-[55px] h-[160px] font-projectTitle text-white bg-pBgModal bg-no-repeat bg-cover">
          {title}
        </div>

        <div className="flex flex-col justify-between font-projectModal bg-black mx-4 tablet:mx-0 h-[437px] ">
          <div className="flex flex-col w-full h-full items-center justify-center px-16 tablet:px-0 tablet:leading-[46px] text-center gap-8 whitespace-pre-wrap">
            <div className="text-[40px] text-white ">
              {children ? children : content}
            </div>
            <div className="text-sm text-white">
              {subText}
            </div>
          </div>

          <div className="flex w-full items-center justify-center py-6">
            <Button service={{
              status: true,
            }} onClick={changeHandlerActive} className={"bg-pColorBlue"} classInit={"bg-pColorBlue transition  hover:bg-pModalPurpur"} >
              {btnContent}
            </Button>
          </div>
        </div>

      </div>

    </div>
  );
};

export default Modal;