import React, {useEffect, useRef} from "react"
import classNames from "classnames";
import {ReactComponent as IconClose} from "../../assets/close.svg";
import {observer} from "mobx-react-lite";

const Button = ({children, className, status, onClick, alertMessagedInit, disconnect, classInit}) => {

  const buttonRef = useRef()


  return (
    <div className={"flex items-center gap-4"}>
      <div className={"flex items-center gap-2 brd"}>
        <div onClick={onClick} className={classNames([
          "w-[224px] h-[70px] m-2 transition flex flex-col items-center justify-center cursor-pointer",
          {[classInit]: !status},
          {[className]: status},
          {"bg-black hover:bg-pModalGray": !status && !alertMessagedInit && !classInit},
          { "bg-pModalBlue": alertMessagedInit && !disconnect && !status },
          {"w-[325px]": !disconnect || !status},
        ])}>
          <div className="text-white font-[400] uppercase text-[26px]">
            {children}
          </div>
        </div>
      </div>
      {
        !!disconnect && status && (
          <div className="brd">
            <div onClick={disconnect}
                 className={"m-2 cursor-pointer w-[69px] h-[69px] flex flex-col items-center justify-center bg-pColorRed transition hover:bg-pModalPink"}>
              <IconClose/>
            </div>
          </div>
        )
      }
    </div>
  );
};

export default observer(Button);